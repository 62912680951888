import React from 'react';
import styled from 'styled-components';


const DiagramStyle = styled.p`
  background-image: url("./graph-paper.png");
  background-repeat: repeat;
`

function Diagram(image) {
 return (
    <DiagramStyle>
        <img src={image.image} alt={image.desc} />
        <br />
        {image.desc}
    </DiagramStyle>
  );
}

export default Diagram;