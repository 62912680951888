import React from 'react';
import './index.css';
import { Route, Switch } from 'react-router-dom';

import LandingPage from './LandingPage';
import QTH from './QTH';

function App() {
  return (
    <Switch>
      <Route exact path="/" component={LandingPage} />
      <Route path="/qth" component={QTH} />
    </Switch>
  );
}

export default App;