import React from 'react';
import logo from './logo.svg';
import './App.css';

import Navigation from './Navigation';
import AntennaSetup from './antenna-setup.png';
import ShackSetup from './shack-setup.png';
import Diagram from './Diagram';

function Qth() {
  return (
    <div className="App">
      <header className="App-header">
        <Navigation />
        <img src={logo} className="App-logo" alt="logo" />
        <p className="App-header-text">
          K7JLX's QTH (Home station)
        </p>
      </header>
      <p>
        The QTH has fixed radio infrastructure including two J-Pole antennas and an HF vertical shared with a neighbhor. These antennas provide recpetion and transmission capabilities in and around the 70cm, 2m, and 6-80m bands. All antennas have an earth ground, and there are lightning arresters built in at the feed line's ingress point.
        <br/>
        There are three feedlines that enter the QTH. One connects to the HF antenna via a shunt switch, one handles UHF/VHF frequencies via a duplexer. The final feedline is a spare that can be used to hook up temporary or experimental antennas. All feedline in the system is LMR-400 to provide low loss connections, and to allow flexibility in the event the feedline needs to support additional UHF/VHF antennas or radios. The feedline length for the UHF and VHF anennas is about 100ft, so low loss cables are important to keep feed line losses down.
        <Diagram image={AntennaSetup} desc={String("Block diagram of my antenna configuration")} />
      </p>
      <p>
        <Diagram image={ShackSetup} desc={String("Block diagram of my ham radio shack")} />
      </p>
    </div>
  );
}

export default Qth;
