import React from 'react';
import logo from './logo.svg';
import './App.css';

import siteConfig from './siteConfig';
import VitaslStatsTable from './VitalStatsTable';
import Navigation from './Navigation';

function LandingPage() {
  return (
    <div className="App">
      <header className="App-header">
        <Navigation />
        <img src={logo} className="App-logo" alt="logo" />
        <p className="App-header-text">
          Welcome to {siteConfig.callSign}'s ham radio page.
        </p>
        <VitaslStatsTable />
      </header>
    </div>
  );
}

export default LandingPage;