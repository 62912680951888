import React from 'react';
import {useTable} from 'react-table';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

import siteConfig from './siteConfig';

function VSTable({columns, data}) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const VSStyle = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 0px;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.1rem;
      padding-right: 0.8rem;
      border-bottom: 0px;
      border-right: 0px;
      text-align: left;
      font-size: calc(8px + 2vmin);

      :last-child {
        border-right: 0;
      }
    }
  }
`

function VitalStatsTable() {
  // Create vital stats data.
  const VSData = [
    {
      stat: "Name",
      value: siteConfig.hamName
    },
    {
      stat: "Location",
      value: siteConfig.hamLocation
    },
    {
      stat: "Ameture license class",
      value: siteConfig.licenseClass
    },
    {
      stat: <Link to="/qth">Base station QTH</Link>,
      value: siteConfig.baseStnQth
    },
  ];

  // Define columns
  const VSCols = [
    {
      Header: "Vital stats",
      columns: [
        {
          accessor: "stat"
        },
        {
          accessor: "value"
        }
      ]
    }
  ];

  return (
    <VSStyle>
      <VSTable columns={VSCols} data={VSData}/>
    </VSStyle>
  );
}

export default VitalStatsTable;