import React from 'react';
import {Link} from 'react-router-dom';

import './index.css';

function Navigation() {
 return (
    <span>
      <Link to="/">Home</Link>&nbsp;|&nbsp; 
      <Link to="/qth">QTH</Link>
    </span>
  );
}

export default Navigation;